// Plugin for application configuration

const isProd = (process.env.VUE_APP_ENV === 'prod')

const config = {
  stripe: isProd ? {
    clientId: 'ca_HpeJM9r9ybJTZJhfdUwJ0GT7CztUaeFM'
  } : {
    clientId: 'ca_HpeJvHJQDzjX7R6wAr6wHkNhEUWXbcOE'
  }
}

export default {
  install(vue) {
    Object.defineProperty(vue.prototype, '$config', {
      get () { return config }
    })
  }
}
