export default {
  install(vue) {
    vue.mixin({
      computed: {
        user () {
          return this.$store.getters.user
        }
      }
    })
  }
}
