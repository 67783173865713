import { firestoreAction } from 'vuexfire'
import fb from '@/firebase'

const COLLECTION = 'follows'
const collection = fb.db.collection(COLLECTION)

const hasFollow = (follows, follow) => {
  const followEntries = Object.entries(follow)
  return follows.some(
    item => followEntries.every(
      ([key, value]) => item[key] === value
    )
  )
}

const getFollowersQS = (account) => collection
  .where('followID', '==', account)
const getFollowedQS = (account) => collection
  .where('follower', '==', account)

export default {
  state: {
    follows: null
  },
  getters: {
    follows: state => state.follows,
    getFollow: state => follow => state.follows.find(
      item => Object.entries(item).every(
        ([key, value]) => follow[key] === value
      )
    ),
    getFollowAccounts: state => state.follows
      .filter(follow => follow.followType === 'account')
      .map(follow => follow.followID),
    getFollowTags: state => state.follows
      .filter(follow => follow.followType === 'tag')
      .map(follow => follow.followID),
    followedCount: state => state.follows ? state.follows.length : 0
  },
  mutations: {
    addFollow(state, follow) {
      if (!Array.isArray(state.follows)) {
        state.follows = []
      }

      state.follows.push(follow)
    },
    deleteFollow(state, follow) {
      const followIndex = state.follows.findIndex(
        item => item.id === follow.id
      )

      if (followIndex >= 0) {
        delete state.follows[followIndex]
      }
    }
  },
  actions: {
    follow({ state }, follow) {
      let result = false

      if (!hasFollow(state.follows, follow)) {
        result = collection.add(follow)
          .then(docRef => {
            this.$app.logInfo('Followed', follow)
            fb.analytics.logEvent('join_group', {group_id: follow})
            return docRef
          })
          .catch(error => this.$app.logError(
            'Issue following', { error: error.message, follow }
          ))
      }

      return result
    },
    unfollow({ getters }, follow) {
      const stateFollow = getters.getFollow(follow)
      let result = false

      if (stateFollow) {
        result = collection.doc(stateFollow.id).delete()
          .then(() => {
            this.$app.logInfo('Unfollowed', follow)
          })
          .catch(error => this.$app.logError(
            'Issue unfollowing', { error: error.message, follow }
          ))
      }

      return result
    },
    getFollowers(context, account) {
      return getFollowersQS(account).get()
        .catch(error => this.logError('Issue getting followers', { error: error.message }))
    },
    getFollowerCount(context, account) {
      return getFollowersQS(account).get()
        .then(qs => qs.size)
        .catch(error => this.logError('Issue getting followers', { error: error.message }))
    },
    getFollowed(context, account) {
      return getFollowedQS(account).get()
        .catch(error => this.logError('Issue getting followed', { error: error.message }))
    },
    getFollowedCount(context, account) {
      return getFollowedQS(account).get()
        .then(qs => qs.size)
        .catch(error => this.logError('Issue getting followed', { error: error.message }))
    },
    bindFollows(context, payload) {
      const $app = this.$app

      return firestoreAction((context, payload) => {
        $app.logInfo('Binding follows to account')
        return context.bindFirestoreRef(
          'follows',
          getFollowedQS(payload)
        )
      })(context, payload)
    }
  }
}
