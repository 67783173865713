import stringify from 'json-stable-stringify'
import { firestoreAction } from 'vuexfire'
import fb from '@/firebase'
import lists from '@/lists'

const COLLECTION = 'wishlists'

export default {
  state: {
    list: {
      account: null,
      modified: null,
      items: []
    }
  },
  mutations: {
    addWishlistItem (state, payload) {
      state.list = lists.addItem(state.list, payload)
    },
    removeWishlistItem (state, payload) {
      if (!state.list) {
        state.list = {
          modified: new Date().getTime(),
          items: []
        }
      }

      if (typeof payload === 'object') {
        state.list.items = state.list.items.filter(
          item => stringify(payload) !== stringify(item)
        )
        state.list.modified = new Date().getTime()
      } else {
        state.list.items.splice(payload, 1)
        state.list.modified = new Date().getTime()
      }
    },
    clearWishlist (state) {
      state.list = {
        account: null,
        modified: new Date().getTime(),
        items: []
      }
    },
    setListAccount (state, payload) {
      state.list.account = payload
    }
  },
  actions: {
    storeList ({ commit, state, rootState }) {
      const $app = this.$app
      const user = rootState.user.user

      commit('setListAccount', user.account)

      const list = {
        account: state.list.account,
        modified: state.list.modified,
        items: state.list.items
      }

      return fb.db.collection(COLLECTION).doc(user.uid)
        .set(list)
        .then(() => $app.logInfo('Stored wishlist'))
        .catch(error =>
          $app.logError('Failed to store wishlist', {
            error: error.message, list
          })
        )
    },
    addWishlistItem ({ commit, dispatch }, payload) {
      const $app = this.$app

      commit('addWishlistItem', payload)
      fb.analytics.logEvent('add_to_wishlist', {
        currency: payload.currency,
        items: [{
          id: payload.id,
          name: payload.title,
          price: payload.unitPrice,
          quantity: payload.quantity
        }],
        value: Number.parseFloat(payload.unitPrice) * Number.parseFloat(payload.options.quantity)
      })
      $app.logInfo('Added item to wishlist', payload)

      return dispatch('storeList')
    },
    removeWishlistItem ({ commit, dispatch }, payload) {
      const $app = this.$app

      commit('removeWishlistItem', payload)
      $app.logInfo('Removed item from wishlist', payload)

      return dispatch('storeList')
    },
    bindWishlistRef(context, payload) {
      const $app = this.$app

      return firestoreAction((context, payload) => {
        $app.logInfo('Binding wishlist to user ID')
        return context.bindFirestoreRef(
          'list',
          fb.db.collection(COLLECTION).doc(payload.id)
        )
      })(context, payload)
    }
  },
  getters: {
    list (state) {
      return state.list
    }
  }
}
