import accounts from '@/data/blacklistaccount.json'
import words from '@/data/blacklistword.json'

const replaceBoundaries = [
  '',
  '-',
  '–',
  '—',
  '_',
  '|',
  '.'
]

export default {
  state: {
    accounts: accounts.blacklist,
    words: words.blacklist
  },
  getters: {
    accountBlacklist: state => state.accounts,
    wordBlacklist: state =>
      state.words.map(word =>
        /\s/g.test(word) ? replaceBoundaries.map(
          boundary => word.replace(/\s/g, boundary)
        ) : word
      ).flat()
  }
}
