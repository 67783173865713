import VueLogger from 'vuejs-logger'

const isProduction = process.env.NODE_ENV === 'production'

const loggerOptions = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

const logLevels = ['debug', 'info', 'warn', 'error', 'fatal']
const log_method = (level) => {
  if (logLevels.indexOf(level) === -1) {
    return false
  } else {
    return function(message, data, user) {
      user = this.user || user
      this.$log[level](
        message,
        JSON.stringify({
          'user.uid': user && user.uid,
          'user.session_id': user && user.session_id,
          ...data
        })
      )
    }
  }
}

export default {
  install(vue) {
    vue.use(VueLogger, loggerOptions)

    vue.prototype.logDebug = log_method('debug')
    vue.prototype.logInfo = log_method('info')
    vue.prototype.logWarn = log_method('warn')
    vue.prototype.logError = log_method('error')
    vue.prototype.logFatal = log_method('fatal')
  }
}
