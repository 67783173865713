import fb from '@/firebase'
import TagList from '@/aggroot/taglist'
import { postStatus } from '@/enums'

const COLLECTION = 'posts'

export default {
  state: {},
  getters: {},
  actions: {
    getPost(context, payload) {
      return fb.db.collection(COLLECTION).doc(payload).get()
    },
    deletePost ({ rootState, dispatch }, payload) {
      const $app = this.$app
      const user = rootState.user.user

      return fb.db.collection(COLLECTION).doc(payload).get()
        .then(doc => doc.get('account'))
        .then(account => dispatch('checkAndClearPinnedPost', { account, postID: payload }))
        .then(() => fb.db.collection(COLLECTION).doc(payload).delete())
        .then(() => {
          $app.logInfo('Deleted post from store', {postId: payload})

          return fb.storage.ref(`posts/${ user.account }/${ payload }`)
            .delete()
            .catch(error => {
              $app.logError('Failed to delete post media from storage', error)
            })
        })
        .then(() => {
          $app.logInfo('Deleted media from storage')
        })
        .then(() => {})
        .catch(error => {
          $app.logError('Failed to delete post from store', error)
        })
    },
    createPost({ rootState, dispatch }, { postTitle, postTags, postRegion, uploadCallback }) {
      const $app = this.$app
      const user = rootState.user.user
      let postID = null
      let mediaUrl = null
      postTags = new TagList(postTags)

      return fb.db.collection(COLLECTION)
        .add({
          'userId': user.id,
          'account': user.account,
          'title': postTitle,
          'tags': postTags,
          'region': postRegion.toLowerCase(),
          'created': new Date().getTime(),
          'status': postStatus.PROCESSING
        })
        .catch((error) => {
          dispatch('setLoading', false)
          $app.logError('Error creating post', { error: error.message })
        })
        .then((docRef) => {
          postID = docRef.id
          $app.logInfo('Post created', { postID })
          dispatch('updateTags', {
            tags: postTags,
            collection: COLLECTION,
            oldTags: false
          })
          mediaUrl = `posts/${ user.account }/${ postID }`
          return uploadCallback(mediaUrl)
        })
        .then(uploadDetails => {
          $app.logInfo('Upload complete', { uploadDetails })
          return fb.db.collection(COLLECTION)
            .doc(postID)
            .update({
              'mediaType': uploadDetails.type,
              'media': mediaUrl
            })
            .then(() => postID)
        })
    },
    getPostCountForAccount(context, account) {
      return fb.db.collection(COLLECTION)
        .where('account', '==', account)
        .get()
        .then(qs => qs.size)
        .catch(error => this.$app.logError(
          'Issue getting post count',
          { error: error.message }
        ))
    },
    getPostsForAccount(context, payload) {
      const $app = this.$app
      const account = payload

      return fb.db.collection(COLLECTION)
        .where('account', '==', account)
        .orderBy('created', 'desc')
        .get()
        .catch(error => {
          $app.logError('Failed to load posts from storage', {account, error})
        })
    },
    getPostTagsForAccount(context, payload) {
      const $app = this.$app
      const account = payload

      return fb.db.collection(COLLECTION)
        .where('account', '==', account)
        .orderBy('created', 'desc')
        .get()
        .then(query => {
          let tags = new Set()
          query.forEach(doc => {
            const docTags = doc.get('tags')

            if (docTags) docTags.forEach(tag => tags.add(tag))
          })
          return Array.from(tags).sort()
        })
        .catch(error => {
          $app.logError('Issue in getPostTagsForAccount', {account, error})
        })
    },
    getPostsForTag(context, payload) {
      const $app = this.$app
      const tag = payload

      return fb.db.collection(COLLECTION)
        .where('tags', 'array-contains', tag)
        .orderBy('created', 'desc')
        .get()
        .catch(error => {
          $app.logError('Failed to load posts from storage', {tag, error})
        })
    },
    addPostProduct(context, { postID, productID }) {
      return fb.db.collection(COLLECTION).doc(postID).get()
        .then(postSnapshot => fb.db.collection(COLLECTION).doc(postID).update(
          'products',
          postSnapshot.get('products') ?
            [...postSnapshot.get('products'), productID] :
            [productID]
        ))
    },
    deletePostProduct(context, { postID, productID }) {
      return fb.db.collection(COLLECTION).doc(postID).get()
        .then(postSnapshot => fb.db.collection(COLLECTION).doc(postID).update(
          'products',
          postSnapshot.get('products')
            .filter(Boolean)
            .filter(p => p !== productID)
        ))
    }
  }
}
