import territories from '@/data/territories.json'

export default {
  state: {
    territories: territories
  },
  getters: {
    territories: (state) => state.territories,
    territory: (state, getters) =>
      (countryCode) => getters.territories[countryCode]
  }
}