import { ulid } from 'ulid'
import fb from '@/firebase'
import { firestoreAction } from 'vuexfire'

const CONVO_COLLECTION = 'conversations'
const MESSAGE_COLLECTION = 'messages'
const PSTATE_TEMPLATE = {
  deleted: false,
  muted: false,
  read: false
}

function areValidArrays() {
  return Array.from(arguments)
    .every(arg =>
      Array.isArray(arg) &&
      arg.length > 0)
}

const messages = {
  state: {
    conversations: [],
    currentConversation: null,
    messages: []
  },
  getters: {
    conversationIndex: state =>
      participants =>
        areValidArrays(state.conversations, participants) &&
        state.conversations.findIndex(
          c => c.participants.every(
            p => participants.includes(p)
          )
        ),
    conversation: state =>
      participants =>
        areValidArrays(state.conversations, participants) &&
        state.conversations.find(
          c => c.participants.every(
            p => participants.includes(p)
          )
        ),
    conversations: state => state.conversations,
    currentConversation: state => state.currentConversation,
    messages: state => state.messages.filter(m => m.recipient !== m.sender)
  },
  mutations: {
    addConversation(state, convo) {
      state.conversations.push(convo)
    },
    updateConversation(state, { index, convo }) {
      state.conversations[index] = convo
    },
    setCurrentConversation(state, convo) {
      state.currentConversation = convo
    }
  },
  actions: {
    bindConversations(context, payload) {
      const $app = this.$app

      return firestoreAction((context, account) => {
        $app.logInfo('Binding conversations to account')
        return context.bindFirestoreRef(
          'conversations',
          fb.db.collection(CONVO_COLLECTION)
            .where('participants', 'array-contains', account)
        )
      })(context, payload)
    },
    bindCurrentConversation(context, payload) {
      const $app = this.$app

      return firestoreAction((context, conversationID) => {
        $app.logInfo('Binding current conversation on ID', {id: conversationID})
        return context.bindFirestoreRef(
          'currentConversation',
          fb.db.collection(CONVO_COLLECTION).doc(conversationID)
        )
      })(context, payload)
    },
    bindMessages(context, payload) {
      const $app = this.$app

      return firestoreAction((context, account) => {
        $app.logInfo('Binding messages to account')
        return context.bindFirestoreRef(
          'messages',
          fb.db.collection(MESSAGE_COLLECTION)
            .where('recipient', '==', account)
            .orderBy('sent', 'asc')
        )
      })(context, payload)
    },
    initConversation(
      { getters, dispatch, commit },
      { participants, reader }) {
      let convo = getters.conversation(participants)

      if (convo) {
        dispatch('bindCurrentConversation', convo.id)
          .then(() => {
            dispatch('markConversationAsRead', {
              id: convo.id,
              account: reader
            })
          })
      } else {
        convo = messages.methods.createNewConvo(participants)
        commit('setCurrentConversation', convo)
        this.$app.logInfo('Created new conversation', { convo })
      }
    },
    storeConversation (context, convo) {
      const $app = this.$app
      const collection = fb.db.collection(CONVO_COLLECTION)

      return convo.id ? collection.doc(convo.id)
          .update(convo)
          .then(() => {
            $app.logInfo('Updated conversation', {id: convo.id})
            return convo.id
          })
          .catch(error => {
            $app.logError('Failed to update conversation', {error: error.message})
          }) :
        collection.add(convo)
          .then(docRef => {
            $app.logInfo('Created conversation', {id: docRef.id})
            return docRef.id
          })
          .catch(error => {
            $app.logError('Failed to create conversation', {error: error.message})
          })
    },
    storeMessage (context, message) {
      const $app = this.$app
      const collection = fb.db.collection(MESSAGE_COLLECTION)

      return message.id ? collection.doc(message.id)
          .update(message)
          .then(() => {
            $app.logInfo('Updated message', {id: message.id})
            return message.id
          })
          .catch(error => {
            $app.logError('Failed to update message', {error: error.message})
          }) :
        collection.add(message)
          .then(docRef => {
            $app.logInfo('Created message', {id: docRef.id})
            return docRef.id
          })
          .catch(error => {
            $app.logError('Failed to create message', {error: error.message})
          })
    },
    sendConversationMessage({ dispatch, getters, rootState }, message ) {
      const sender = rootState.user.user.account
      const convo = getters.currentConversation
      const recipient = convo.participants.filter(p => p !== sender)[0]

      convo.participantState[sender].read = true
      convo.participantState[recipient].read = false
      convo.messages.push(
        messages.methods.createNewMessage({
          sender: sender,
          recipient: recipient,
          ...message
        })
      )

      this.$app.logInfo('Added message to conversation')

      return dispatch('storeConversation', convo)
        .then(convoID =>
          (convoID && !getters.currentConversation.id) ?
            dispatch('bindCurrentConversation', convoID) :
            undefined
        )
    },
    sendMessage({ dispatch, rootState }, payload) {
      const sender = rootState.user.user.account
      return dispatch('getFollowers', sender)
        .then(qs => [sender, ...qs.docs.map(ds => ds.get('follower'))])
        .then(followers => Promise.all(
          followers.map(f => {
            const message = messages.methods.createNewMessage({
              sender: sender,
              recipient: f,
              ...payload
            })

            return dispatch('storeMessage', message)
          })
        ))
    },
    getMessagesToFollowers(context, account) {
      return fb.db.collection(MESSAGE_COLLECTION)
        .where('sender', '==', account)
        .where('recipient', '==', account)
        .orderBy('sent', 'asc')
        .get()
    },
    getMessages(context, account) {
      return fb.db.collection(MESSAGE_COLLECTION)
        .where('recipient', '==', account)
        .orderBy('sent', 'asc')
        .get()
    },
    getNoticeMessage(context, id) {
      return fb.db.collection(MESSAGE_COLLECTION)
        .doc(id)
        .get()
    },
    markMessageAsRead(context, id) {
      return fb.db.collection(MESSAGE_COLLECTION)
        .doc(id)
        .update({read: true})
        .then(this.$app.logInfo('Marked message as read', { id }))
    },
    markConversationAsRead(context, { id, account }) {
      let updateDoc = {}
      updateDoc[`participantState.${account}.read`] = true
      return fb.db.collection(CONVO_COLLECTION)
        .doc(id)
        .update(updateDoc)
        .then(this.$app.logInfo('Marked conversation as read', { id, account }))
    }
  },
  methods: {
    createNewConvo(participants, messages) {
      return {
        participants: participants,
        participantState:
          this.convertParticipantArrayToLookup(participants),
        created: new Date().getTime(),
        messages: messages || []
      }
    },
    createNewMessage({ sender, recipient, ...rest }) {
      const { text = '', media = '', post = '', product = '' } = rest
      return {
        ulid: ulid(),
        sender,
        recipient,
        sent: new Date().getTime(),
        text,
        media,
        post,
        product,
        read: Boolean(sender === recipient)
      }
    },
    convertParticipantArrayToLookup(participants) {
      return participants.reduce(
        (obj, p) => {
          obj[p] = {...PSTATE_TEMPLATE}
          return obj
        }, {}
      )
    }
  }
}

export default messages
