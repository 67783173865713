import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'

import account from './account'
import address from './address'
import blacklist from './blacklist'
import cart from './cart'
import currency from './currency'
import follows from './follows'
import industry from './industry'
import messages from './messages'
import orders from './orders'
import products from './products'
import rating from './rating'
import search from './search'
import shared from './shared'
import stream from './stream'
import tags from './tags'
import tax from './tax'
import territory from './territory'
import user from './user'
import wish from './wish'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    account,
    address,
    blacklist,
    cart,
    currency,
    follows,
    industry,
    messages,
    orders,
    products,
    rating,
    search,
    shared,
    stream,
    tags,
    tax,
    territory,
    user,
    wish
  },
  mutations: vuexfireMutations
})
