import currencies from '@/data/currencies.json'

export default {
  state: {
    currencies: currencies
  },
  getters: {
    currencies: (state) => {
      return state.currencies
    },
    currency: (state, getters) => (currencyCode) => {
      return getters.currencies[currencyCode]
    }
  }
}