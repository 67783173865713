export const isEmptyObject = (obj) =>
  Boolean(Object.keys(obj).length === 0 && obj.constructor === Object)

export const isPWA = () =>
  window && window.matchMedia("(display-mode: standalone)").matches

export const isIos = () =>
  /iphone|ipad|ipod/.test(
    window.navigator.userAgent.toLowerCase()
  )
