import fb from '@/firebase'

export default {
  state: {
    prevRoute: {},
    searchQuery: null,
    searchResults: [],
    searchLoading: false
  },
  getters: {
    searchLoading: (state) => state.searchLoading,
    searchQuery: (state) => state.searchQuery,
    searchResults: (state) => state.searchResults
  },
  mutations: {
    clearSearch(state) {
      state.searchResults = []
      state.searchQuery = null
    },
    setPrevRoute(state, payload = {}) {
      state.prevRoute = payload
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload
    },
    setSearchResults(state, payload = []) {
      state.searchResults = payload
    },
    setSearchLoading(state, payload = false) {
      state.searchLoading = payload ? '#7070a0' : false
    }
  },
  actions: {
    executeSearch({ commit }, query) {
      if (query) {
        commit('clearSearch')
        commit('setSearchLoading', true)
        commit('setSearchQuery', query)

        const searchParams = {
          query: query
        }

        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          })
        })
          .catch(error =>
            this.$app.logInfo(
              'Cannot get geoposition', { error: error.message }
            )
          )
          .then(position => {
            if (position && position.coords) {
              searchParams.position = {
                lat: position.coords.latitude,
                lon: position.coords.longitude
              }
            }

            return fb.func.httpsCallable('search')(searchParams)
          })
          .then(response => {
            commit('setSearchLoading', false)
            fb.analytics.logEvent('search', {search_term: query})
            const searchData = response.data
            const resultOrder = ['products', 'posts', 'accounts', 'tags']

            if (searchData && searchData.hits) {
              this.$app.logInfo(
                'Search ' + query, {hits: searchData.hits.total.value}
              )
              let searchResults = null

              if (searchData.hits.hits) {
                const results = {}
                searchData.hits.hits.forEach(
                  hit => hit._index in results ?
                    results[hit._index].push(hit) :
                    results[hit._index] = [ hit ]
                )
                searchResults = resultOrder
                  .map(o => o in results ? results[o] : [])
                  .flat()
                  .filter(Boolean)
              }
              commit('setSearchResults', searchResults)
            }
            return searchData
          });
      }
    }
  }
}
