import { ulid } from 'ulid'
import { firestoreAction } from 'vuexfire'
import fb from '@/firebase'
import axios from 'axios'

const COLLECTION = 'addressbooks'

export default {
  state: {
    address: {
      book: [],
      default: {}
    }
  },
  mutations: {
    createAddress(state, payload) {
      if (!state.address) state.address = {
        book: [],
        default: {}
      }

      state.address.book.push(payload)
    },
    updateAddress(state, payload) {
      const index = state.address.book.findIndex(address => address.ulid === payload.ulid)

      state.address.book[index] = payload
    },
    removeAddress(state, payload) {
      state.address.book = state.address.book.filter(
        address => address.ulid !== payload
      )
    },
    setDefaultAddress(state, payload) {
      state.address.default = payload
    }
  },
  actions: {
    bindAddressBookRef(context, payload) {
      const $app = this.$app

      return firestoreAction((context, payload) => {
        $app.logInfo('Binding address book to account')
        return context.bindFirestoreRef(
          'address',
          fb.db.collection(COLLECTION).doc(payload)
        )
      })(context, payload)
    },
    updateDefault({ commit, state }, payload) {
      if (payload.isDefault) {
        const $app = this.$app

        if (state.address.default) {
          commit('updateAddress', {
            ...state.address.default,
            isDefault: false
          })
          $app.logInfo('Removed old default address')
        }
        commit('setDefaultAddress', payload)
        $app.logInfo('Set as default address')
      }
    },
    createAddress({ commit, dispatch, state, rootState }, payload) {
      const $app = this.$app
      const user = rootState.user.user
      const address = {
        ulid: ulid(),
        ...payload
      }

      return dispatch('geocodeAddress', address)
        .then(geolocation => {
          address.geolocation = geolocation
          commit('createAddress', address)
          $app.logInfo('Added address to book', address)
        })
        .then(() => dispatch('updateDefault', address))
        .then(() => fb.db.collection(COLLECTION)
          .doc(user.account)
          .set(state.address)
        )
        .then(() => $app.logInfo('Stored address book'))
        .catch(error =>
          $app.logError('Failed to store address book', {error: error.message})
        )
    },
    updateAddress({ commit, dispatch, state, rootState }, payload) {
      const $app = this.$app
      const user = rootState.user.user

      return dispatch('geocodeAddress', payload)
        .then(geolocation => payload.geolocation = geolocation)
        .then(() => dispatch('updateDefault', payload))
        .then(() => {
          commit('updateAddress', payload)
          $app.logInfo('Updated address in book', payload)

          return fb.db.collection(COLLECTION)
            .doc(user.account)
            .set(state.address)
        })
        .then(() => $app.logInfo('Stored address book'))
        .catch(error =>
          $app.logError('Failed to store address book', {error: error.message})
        )
    },
    deleteAddress({ commit, state, rootState }, payload) {
      const $app = this.$app
      const user = rootState.user.user

      commit('removeAddress', payload)
      $app.logInfo('Removed address from book', payload)

      if (state.address.default.ulid === payload.ulid) commit('setDefaultAddress', {})

      return fb.db.collection(COLLECTION).doc(user.account)
        .set(state.address)
        .then(() => {
          $app.logInfo('Stored address book')
        })
        .catch(error => {
          $app.logError('Failed to store address book', {error: error.message})
        })
    },
    setDefaultAddress({ commit }, payload) {
      commit('setDefaultAddress', payload)
    },
    geocodeAddress(context, payload) {
      const addressString = [
        payload.line1.trim(),
        (payload.line2 || '').trim(),
        (payload.city || '').trim(),
        (payload.state || '').trim(),
        (payload.postalCode || '').trim(),
        payload.country.trim()
      ]
        .filter(Boolean)
        .join('+')
        .replace(/[,]/ig, '')
        .replace(/\s/ig, '+')
      const apiURL = [
        'https://maps.googleapis.com',
        '/maps/api/geocode/json?',
        `address=${addressString}&key=${fb.firebaseConfig.apiKey}`
      ].join('')

      return axios.get(apiURL)
        .then(response => response.data.results[0].geometry.location)
        .catch(error => {
          this.$app.logError('Issue contacting Geocode API', {error: error.message})
        })
    },
    getAccountAddresses(context, payload) {
      const $app = this.$app

      return fb.db.collection(COLLECTION)
        .doc(payload).get()
        .then(docRef => docRef.get('book'))
        .catch(error =>
          $app.logError(
            'Failed to get addresses for account',
            payload,
            {error: error.message}
          )
        )
    }
  },
  getters: {
    address(state) {
      return state.address
    },
    addressDefault(state) {
      return state.address.default
    },
    getAddress(state) {
      return function(addressUlid) {
        return state.address.book.find(address => address.ulid === addressUlid)
      }
    },
    getGeoLocations(state) {
      return state.address.book
        .map(address => address.geolocation)
        .filter(Boolean)
    }
  }
}