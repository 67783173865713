import categories from '@/data/taxcategories.json'

export default {
  state: {
    categories
  },
  getters: {
    taxCategories: (state) => {
      return state.categories
    },
    taxCategory: (state) => (code) => {
      return state.categories.find(c =>
        c.code.toLowerCase() === code.toLowerCase()
      )
    }
  },
  mutations: {

  },
  actions: {

  }
}
