import fb from '@/firebase'

const COLLECTION = fb.db.collection('rates')

export default {
  getters: {
    getRating: () => (type, itemID, raterID) =>
      COLLECTION
        .where("item_type", "==", type)
        .where("item_id", "==", itemID)
        .where("rater_id", "==", raterID).get()
        .then(qs => !qs.empty ? qs.docs[0].get('value') : 0)
        .catch(e => this.logError(
          'Error getting rating.',
          { type, itemID, raterID, error: e.message }
        )),

    getAverageRating: () => (type, itemID) =>
      COLLECTION
        .where("item_type", "==", type)
        .where("item_id", "==", itemID).get()
        .then(qs => ({
          numberOfRaters: qs.size,
          averageRating: qs.docs.reduce(
            (acc, rate) => acc += parseFloat(rate.get('value')), 0
          ) / qs.size
        }))
        .catch(e => this.logError(
          'Error getting average rating.',
          { type, itemID, error: e.message }
        )),
  },
  actions: {
    setRating(context, { type, itemID, raterID, value }) {
      return COLLECTION
        .where("item_type", "==", type)
        .where("item_id", "==", itemID)
        .where("rater_id", "==", raterID).get()
        .then(qs => qs.empty ?
          COLLECTION.add({
            item_type: type,
            item_id: itemID,
            rater_id: raterID,
            value
          }) :
          COLLECTION.doc(qs.docs[0].id).update({ value })
        )
        .catch(e => this.logError(
          'Error setting rating.',
          { type, itemID, raterID, value, error: e.message }
        ))
    }
  }
}
