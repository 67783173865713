import Vue from 'vue'
import Router from 'vue-router'
import fb from '@/firebase'
import { version } from "../package.json"
import { isPWA } from '@/util'

import AccountAddressEdit from "@/views/AccountAddressEdit"
import AccountEdit from "@/views/AccountEdit"
import AccountImageEdit from "@/views/AccountImageEdit"
import AccountStripeConnect from "@/views/AccountStripeConnect"
import AddressBook from "@/views/AddressBook"
import Cart from "@/views/Cart"
import Checkout from "@/views/Checkout"
import CheckoutComplete from "@/views/CheckoutComplete"
import CreatePost from "@/views/CreatePost"
import Follows from "@/views/Follows"
import LegalCookies from "@/views/LegalCookies"
import LegalPrivacy from "@/views/LegalPrivacy"
import LegalTerms from "@/views/LegalTerms"
import ManageProducts from "@/views/ManageProducts"
import MessageConversation from "@/views/MessageConversation"
import MessageFollowers from "@/views/MessageFollowers"
import MessageNotice from "@/views/MessageNotice"
import MessageRecipient from "@/views/MessageRecipient"
import Messages from "@/views/Messages"
import OrderDetail from "@/views/OrderDetail"
import Orders from "@/views/Orders"
import ProductChoose from "@/views/ProductChoose"
import ProductEdit from "@/views/ProductEdit"
import ProductVariants from "@/views/ProductVariants"
import Profile from "@/views/Profile"
import PurchaseOrders from "@/views/PurchaseOrders"
import SearchResults from "@/views/SearchResults"
import ShareTarget from "@/views/ShareTarget"
import Shop from "@/views/Shop"
// import Signup from "@/views/Signup"
import SinglePost from "@/views/SinglePost"
import SingleProduct from "@/views/SingleProduct"
import Store from "@/views/Store"
import Stream from "@/views/Stream"
import TagStream from "@/views/TagStream"
import UserEdit from "@/views/UserEdit"
import Wishlist from "@/views/Wishlist"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/index.html',
      alias: '/',
      name: 'stream',
      component: Stream
    },
    {
      path: '/discover/',
      name: 'discover',
      component: Stream
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import(
        /* webpackChunkName: "signup" */ '@/views/Signup')
    },{
      path: '/share',
      name: 'share-target',
      component: ShareTarget,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/legal/terms',
      name: 'legal-terms',
      component: LegalTerms,
      props: { lastUpdated: "21 September, 2020" }
    },
    {
      path: '/legal/privacy',
      name: 'legal-privacy',
      component: LegalPrivacy,
      props: { lastUpdated: "21 September, 2020" }
    },
    {
      path: '/legal/cookies',
      name: 'legal-cookies',
      component: LegalCookies,
      props: { lastUpdated: "21 September, 2020" }
    },
    {
      path: '/search',
      name: 'search',
      component: SearchResults
    },
    {
      path: '/new-post',
      name: 'new-post',
      component: CreatePost,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/stripe/connect',
      alias: '/stripe/connect/edit',
      name: 'account-stripe-connect',
      component: AccountStripeConnect
    },
    {
      path: '/user/checkout',
      name: 'checkout',
      component: Checkout,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/checkout-complete',
      name: 'checkout-complete',
      component: CheckoutComplete,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/orders',
      name: 'orders',
      component: Orders,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/orders/:order_id',
      name: 'order-detail',
      component: OrderDetail,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/cart',
      name: 'cart',
      component: Cart,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/wishlist',
      name: 'wishlist',
      component: Wishlist,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/user/settings',
      name: 'user-edit',
      component: UserEdit,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/tag/:tag',
      name: 'tag-stream',
      component: TagStream
    },
    {
      path: '/:account',
      name: 'profile',
      component: Profile
    },
    {
      path: '/:account/followers',
      name: 'followers',
      component: Follows
    },
    {
      path: '/:account/following',
      name: 'following',
      component: Follows
    },
    {
      path: '/:account/image',
      name: 'account-image',
      component: AccountImageEdit,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/messages',
      name: 'messages',
      component: Messages,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/messages/new',
      name: 'messages-new',
      component: MessageRecipient,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/messages/with/:recipient',
      name: 'messages-convo',
      component: MessageConversation,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:account/messages/followers',
      name: 'messages-followers',
      component: MessageFollowers,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/messages/:message_id',
      name: 'message-detail',
      component: MessageNotice,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/posts/:post_id',
      name: 'single-post',
      component: SinglePost
    },
    {
      path: '/:account/posts/:post_id/manage-products',
      name: 'manage-products',
      component: ManageProducts,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/posts/:post_id/manage-products/add',
      name: 'post-add-product',
      component: ProductEdit,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/posts/:post_id/manage-products/select',
      name: 'post-select-product',
      component: ProductChoose,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/shop',
      name: 'account-products',
      component: Shop
    },
    {
      path: '/:account/store',
      name: 'account-products-manage',
      component: Store,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/store/add',
      name: 'add-product',
      component: ProductEdit,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/products/:product_id',
      name: 'single-product',
      component: SingleProduct
    },
    {
      path: '/:account/products/:product_id/edit',
      name: 'edit-product',
      component: ProductEdit,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/products/:product_id/variants',
      name: 'edit-product-variants',
      component: ProductVariants,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/purchase-orders',
      name: 'account-orders',
      component: PurchaseOrders,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/purchase-orders/:order_id',
      name: 'account-order-detail',
      component: OrderDetail,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/addresses',
      name: 'account-addressbook',
      component: AddressBook,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/addresses/add',
      name: 'account-address-add',
      component: AccountAddressEdit,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/addresses/:ulid',
      name: 'account-address-edit',
      component: AccountAddressEdit,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    },
    {
      path: '/:account/settings',
      name: 'account-edit',
      component: AccountEdit,
      meta: {
        requiresAuth: true,
        accountOnly: true
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  router.app.$store.dispatch('setAppLoading', true)
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const accountOnly = to.matched.some(x => x.meta.accountOnly)

  const routeAccount = 'account' in to.params && to.params.account
  const recipient = 'recipient' in to.params && to.params.recipient
  const user = await fb.getCurrentUser()

  const streamRoute = {name: 'stream'}

  if (
    accountOnly && user &&
    (routeAccount !== user.account &&
    recipient !== user.account)
  ) {
    next(streamRoute)
  } else if (requiresAuth && !user) {
    router.app.$store.dispatch('setShowLogin', true)
    next({...streamRoute, query: {next: to.fullPath}})
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  fb.analytics.setCurrentScreen(to.path)
  fb.analytics.logEvent("screen_view", {
    app_name: isPWA() ? "pwa" : "web",
    screen_name: to.path,
    app_version: version
  })

  router.app.$store.dispatch('setAppLoading', false)
  router.app.logInfo('Navigated', {
    'to': to.name,
    'to.path': to.path,
    'from': from.name,
    'from.path': from.path
  })
})

export default router
