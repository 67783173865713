export default {
  state: {
    appLoading: true,
    error: null,
    loading: false,
    navigation: false,
    showLogin: false,
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 3000,
    stateToken: null,
  },
  mutations: {
    setStateToken (state) {
      state.stateToken = state.stateToken || Math.random().toString(36).slice(2)
    },
    setShowLogin (state, payload) {
      state.showLogin = payload
    },
    setAppLoading (state, payload) {
      state.appLoading = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    clearError (state) {
      state.error = null
    },
    showSnackbar (state) {
      state.snackbar = true
    },
    setSnackbar (state, payload) {
      state.snackbar = payload
    },
    setSnackbarText (state, payload) {
      state.snackbarText = payload
    },
    setSnackbarTimeout (state, payload) {
      state.snackbarTimeout = payload
    },
    setNav(state, payload) {
      state.navigation = payload
    }
  },
  actions: {
    clearError ({commit}) {
      commit('clearError')
    },
    setShowLogin ({commit}, payload) {
      if (payload) {
        commit('clearError')
        commit('setNav', false)
      }

      commit('setShowLogin', payload)
    },
    setError ({commit}, payload) {
      commit('setError', payload)
    },
    setAppLoading ({commit}, payload) {
      commit('setAppLoading', payload)
    },
    setLoading ({commit}, payload) {
      commit('setLoading', payload)
    },
    showSnackbar ({commit}) {
      commit('showSnackbar')
    },
    setSnackbar ({commit}, payload) {
      commit('setSnackbar', payload)
    },
    setSnackbarText ({commit}, payload) {
      commit('setSnackbarText', payload)
    },
    triggerSnackbar ({commit}, payload) {
      commit('setSnackbarText', payload)
      commit('showSnackbar')
    },
    setNav({commit}, payload) {
      commit('setNav', payload)
    }
  },
  getters: {
    appLoading: state => state.appLoading,
    error: state => state.error,
    loading: state => state.loading,
    navigation: state => state.navigation,
    showLogin: state => state.showLogin,
    snackbar: state => state.snackbar,
    snackbarText: state => state.snackbarText,
    snackbarTimeout: state => state.snackbarTimeout,
    stateToken: state => state.stateToken,
  }
}
