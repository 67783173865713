import { firestoreAction } from 'vuexfire'
import fb from '@/firebase'
import Product from '@/aggroot/product'

const COLLECTION = 'products'

export default {
  state: {
    accountProducts: []
  },
  getters: {
    accountProducts: (state) => state.accountProducts
  },
  mutations: {
    setAccountProducts(state, payload) {
      state.accountProducts = payload
    }
  },
  actions: {
    createProductFromCSV(context, { results, user }) {
      const $app = this.$app
      const product = Product.fromObj({
        account: user.account,
        userId: user.id,
        hasMedia: false,
        ...results.data,
        unitSize: results.data['unit-size'],
        taxCode: results.data["tax-category"],
        tags: results.data.tags ?
          results.data.tags.split(',') : [],
        prices: results.data.prices ?
          results.data.prices.split(',').map(p => {
            const [currency, unitPrice, shipping] = p.split(':')
            return { currency, unitPrice, shipping }
          }) : [],
        customOptions: results.data.options ?
          results.data.options.split(';').map(o => {
            const [description, options] = o.split(':')
            return { description, 'options': options.split(',')}
          }) : []
      })

      return fb.db.collection(COLLECTION).add(product.toObj())
        .then(docRef => {
          $app.logInfo('Product created!', {productID: docRef.id})
        })
        .catch(error => {
          $app.logError('Failed to create product', { error: error.message })
        })
    },
    createProduct({ commit }, product) {
      return fb.db.collection(COLLECTION)
        .add(product.toObj())
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          this.$app.logError('Error creating product', {error: error.message})
          throw error
        })
    },
    updateProduct({ commit }, product) {
      return fb.db.collection(COLLECTION)
        .doc(product.id)
        .set(product.toObj())
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          this.$app.logError('Error updating product', {error: error.message})
          throw error
        })
    },
    getProduct(context, payload) {
      const $app = this.$app
      return fb.db.collection(COLLECTION)
        .doc(payload)
        .get()
        .then(productRef => Product.fromObj({
          id: productRef.id,
          ...productRef.data(),
        }))
        .catch(error => {
          $app.logError('Failed to get product', {payload, error})
        })
    },
    getStockForProducts(context, payload) {
      const $app = this.$app
      return payload ? fb.db.collection(COLLECTION)
        .where(fb.FieldPath.documentId(), 'in', payload)
        .get()
        .then(qs => qs.docs.reduce((acc, item) => {
          const stock = Number.parseFloat(item.get('stock'))
          acc[item.id] = Number.isNaN(stock) ? false : stock
          return acc
        }, {}))
        .catch(error => {
          $app.logError('Failed to get product', {payload, error})
        }) : false
    },
    getAccountProducts(context, payload) {
      const $app = this.$app
      return fb.db.collection(COLLECTION)
        .where('account', "==", payload)
        .orderBy('created', 'desc')
        .get()
        .then(querySnapshot =>
          querySnapshot.docs.map(productRef =>
            Product.fromObj({
              id: productRef.id,
              ...productRef.data()
            })
          )
        )
        .catch(error => {
          $app.logError('Failed to get products', {payload, error})
        })
    },
    hasAccountProducts({ dispatch }, payload) {
      return dispatch('getAccountProducts', payload)
        .then(querySnapshot => !querySnapshot.empty)
    },
    getProductCountForAccount(context, account) {
      return fb.db.collection(COLLECTION)
        .where('account', '==', account)
        .get()
        .then(qs => qs.size)
        .catch(error => this.$app.logError(
            'Issue getting product count',
            { error: error.message }
        ))
    },
    getProductTagsForAccount(context, payload) {
      const $app = this.$app
      const account = payload

      return fb.db.collection(COLLECTION)
        .where('account', '==', account)
        .orderBy('created', 'desc')
        .get()
        .then(query => {
          let tags = new Set()
          query.forEach(doc => {
            const docTags = doc.get('tags')

            if (docTags) docTags.forEach(tag => tags.add(tag))
          })
          return Array.from(tags).sort()
        })
        .catch(error => {
          $app.logError('Issue in getProductTagsForAccount', {account, error})
        })
    },
    getAccountProductImage(context, payload) {
      return `/${ COLLECTION }/${ payload.account }/${ payload.productId }`
    },
    getPostProducts(context, productArray) {
      return productArray
        && productArray.length
        && fb.db.collection(COLLECTION)
          .where(fb.FieldPath.documentId(), 'in', productArray)
          .get()
    },
    setProductHasMedia(context, { productID, hasMedia }) {
      return fb.db.collection(COLLECTION).doc(productID)
        .update({ hasMedia })
    },
    deleteProduct({ dispatch }, payload) {
      const $app = this.$app

      return fb.db.collection(COLLECTION).doc(payload.productId)
        .delete()
        .then(() => {
          $app.logInfo('Deleted product from store', payload)

          return payload.hasMedia ?
            fb.storage
              .ref(`products/${ payload.account }/${ payload.productId }`)
              .delete() :
            undefined
        })
        .then(() =>
          fb.db.collection('posts')
            .where('products', 'array-contains', payload.productId)
            .get()
        )
        .then(postsSnapshot => Promise.all(
          postsSnapshot.docs.map(postSnapshot =>
            dispatch('deletePostProduct', {
              postID: postSnapshot.id,
              productID: payload.productId
            })
          )
        ))
        .then(() => $app.logInfo('Deleted product from posts'))
        .catch(error => {
          $app.logError('Failed to delete product from store', error)
        })
    },
    bindAccountProductsRef: firestoreAction((context, payload) =>
      context.bindFirestoreRef(
        'accountProducts',
        fb.db.collection(COLLECTION)
          .where('account', "==", payload)
          .orderBy('title')
      )
    )
  }
}
