const generateItem = (product, options, currency) => {
  const optionSearch = Object.entries(options).reduce((acc, [key, value]) => {
    key = key.toLowerCase()
    if (key !== 'quantity') {
      acc[key] = value
    }
    return acc
  }, {})

  const price = product.derivePrice(currency, optionSearch)

  return {
    id: product.id,
    ...product,
    options,
    code: product.deriveSKU(optionSearch),
    stock: product.deriveStock(optionSearch),
    currency: price.currency,
    unitPrice: price.unitPrice
  }
}

export default {
  methods: {
    addToWishlist(product, options, currency, callback) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const item = generateItem(product, options, currency)
        if (callback) callback()
        this.$store.dispatch('addWishlistItem', item)
        this.$store.dispatch('triggerSnackbar', 'Item added to wishlist.')
      }
    },
    addToCart(product, options, currency, callback) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const item = generateItem(product, options, currency)
        if (callback) callback()
        this.$store.dispatch('addCartItem', item)
        this.$store.dispatch('triggerSnackbar', 'Item added to cart.')
      }
    }
  }
}
