import { firestoreAction } from 'vuexfire'
import fb from '@/firebase'
import lists from '@/lists'

const COLLECTION = 'carts'

export default {
  state: {
    cart: {
      account: null,
      modified: null,
      items: []
    }
  },
  mutations: {
    addCartItem (state, payload) {
      state.cart = lists.addItem(state.cart, payload)
    },
    removeCartItem (state, payload) {
      if (!state.cart) {
        state.cart = {
          modified: new Date().getTime(),
          items: []
        }
      }

      state.cart.items.splice(payload, 1)
      state.cart.modified = new Date().getTime()
    },
    updateCartItem (state, payload) {
      state.cart.items[payload.index].options.quantity = payload.quantity
    },
    clearCart (state) {
      state.cart = {
        account: null,
        modified: new Date().getTime(),
        items: []
      }
    },
    setCartAccount (state, payload) {
      state.cart.account = payload
    }
  },
  actions: {
    storeCart ({ commit, state, rootState }) {
      const $app = this.$app
      const user = rootState.user.user

      commit('setCartAccount', user.account)

      const cart = {
        account: state.cart.account,
        modified: state.cart.modified,
        items: state.cart.items
      }

      return fb.db.collection(COLLECTION).doc(user.uid)
        .set(cart)
        .then(() => $app.logInfo('Stored cart'))
        .catch(error =>
          $app.logError('Failed to store cart', {
            error: error.message, cart
          })
        )
    },
    addCartItem ({ commit, dispatch }, payload) {
      const $app = this.$app

      commit('addCartItem', payload)
      fb.analytics.logEvent('add_to_cart', {
        currency: payload.currency,
        items: [{
          id: payload.id,
          name: payload.title,
          price: payload.unitPrice,
          quantity: payload.quantity
        }],
        value: Number.parseFloat(payload.unitPrice) * Number.parseFloat(payload.options.quantity)
      })
      $app.logInfo('Added item to cart', payload)

      return dispatch('storeCart')
    },
    removeCartItem ({ state, commit, dispatch }, payload) {
      const $app = this.$app
      const item = state.cart.items[payload]

      commit('removeCartItem', payload)
      fb.analytics.logEvent('remove_from_cart', {
        currency: item.currency,
        items: [{
          id: item.id,
          name: item.title,
          price: item.unitPrice,
          quantity: item.quantity
        }],
        value: Number.parseFloat(item.unitPrice) * Number.parseFloat(item.options.quantity)
      })
      $app.logInfo('Removed item from cart', {index: payload})

      return dispatch('storeCart')
    },
    updateCartItem ({ commit, dispatch }, payload) {
      const $app = this.$app

      commit('updateCartItem', payload)
      $app.logInfo('Updated item in cart', payload)

      return dispatch('storeCart')
    },
    clearCart ({ commit, dispatch }) {
      const $app = this.$app

      commit('clearCart')
      $app.logInfo('Cleared cart')

      return dispatch('storeCart')
    },
    bindCartRef(context, payload) {
      const $app = this.$app

      return firestoreAction((context, payload) => {
        $app.logInfo('Binding cart to user ID')
        return context.bindFirestoreRef(
          'cart',
          fb.db.collection(COLLECTION).doc(payload.id)
        )
      })(context, payload)
    }
  },
  getters: {
    cart (state) {
      return state.cart
    }
  }
}
