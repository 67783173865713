import fb from '@/firebase'
import lists from '@/lists'

const COLLECTION = 'orders'

export default {
  state: {
    orders: []
  },
  getters: {
    orders: (state) => state.orders
  },
  mutations: {
    addOrder(state, payload) {
      lists
        .initList(state.orders)
        .push(payload)
    }
  },
  actions: {
    saveOrder(context, payload) {
      return fb.db.collection(COLLECTION)
        .doc(payload.orderID)
        .set(payload)
        .then(() => {
          this.$app.logInfo('Added order to store', {order: payload.orderID})
          return payload
        })
        .catch(error =>
          this.$app.logError(
            'Failed to add order to store', { error: error.message }
          )
        )
    },
    findOrders({ commit, state }, payload) {
      const orderIDs = Array.isArray(payload) ? payload : [payload]

      return Promise.all(orderIDs.map(orderID => {
        const stateOrder = state.orders.find(order => order.orderID === orderID)
        let storeOrder = false

        if (!stateOrder) {
          storeOrder = fb.db.collection(COLLECTION)
            .doc(orderID).get()
            .then(orderSnapshot => {
              return orderSnapshot.exists ? orderSnapshot.data() : false
            })

          if (storeOrder) {
            commit('addOrder', storeOrder)
          }
        }

        return stateOrder || storeOrder
      }))
    },
    getCustomerOrders(context, payload) {
      const $app = this.$app

      return fb.db.collection(COLLECTION)
        .where("customer.email", "==", payload)
        .get()
        .catch(error =>
          $app.logError(
            'Failed to read orders from store', { error: error.message }
          )
        )
    },
    getPurchaseOrders(context, payload) {
      const $app = this.$app

      return fb.db.collection(COLLECTION)
        .where("merchant.account", "==", payload)
        .get()
        .catch(error =>
          $app.logError(
            'Failed to read orders from store', { error: error.message }
          )
        )
    },
    updateStatus(context, { orderID, status }) {
      const $app = this.$app
      const docRef = fb.db.collection(COLLECTION).doc(orderID)

      return docRef.get()
        .then(doc => {
          const newStatus = [
            ...doc.get('status') || [],
            {'datetime': new Date().getTime(), status}
          ].sort((a, b) => a.datetime - b.datetime)

          docRef.update({ status: newStatus})

          return newStatus
        })
        .catch(error =>
          $app.logError('Failed to set order status', { error: error.message })
        )
    }
  }
}
