import industry from '@/data/industry.json'

export default {
  state: {
    industry: industry,
  },
  getters: {
    industries: (state) => state.industry,
    industry: (state, getters) =>
      (industryCode) => getters.industries.find(el => el.code === industryCode)
  }
}
