import { ulid } from 'ulid'
import { firestoreAction } from 'vuexfire'
import fb from '@/firebase'
import { userStatus } from '@/enums'

const COLLECTION = 'users'

export default {
  state: {
    user: null,
    justSignedOut: false,
    stripeConnectionCodes: []
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    setFullName (state, payload) {
      state.user = state.user || {}
      state.user.fullName = payload
    },
    setJustSignedOut (state, payload) {
      state.justSignedOut = payload
    },
    addStripeCode (state, payload) {
      state.stripeConnectionCodes.push(payload)
    }
  },
  actions: {
    addStripeCode({ commit }, payload) {
      commit('addStripeCode', payload)
    },
    setJustSignedOut({ commit }, payload) {
      commit('setJustSignedOut', payload)
    },
    signUserUp ({ dispatch, commit, rootGetters }, payload) {
      const $app = this.$app
      let acct = null
      let user = null
      commit('setLoading', true)
      commit('clearError')
      payload.email = payload.email.toLowerCase()
      return fb.auth
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(userCred => {
          user = userCred.user
          $app.logInfo('Created auth user', {'email': payload.email})

          user.sendEmailVerification()
          fb.func.httpsCallable('sendWelcomeEmail')({userEmail: user.email})

          return dispatch("createAccount", payload)
        })
        .then(account => {
          acct = account
          $app.logInfo('Created account', {'account': account.account})
          return fb.db.collection(COLLECTION)
            .doc(user.uid) // Reuse auth user ID
            .set({
              'created': new Date().getTime(),
              'status': userStatus.ACTIVE,
              'account': account.account,
              'taxRegion': payload.taxRegion,
              'currency': payload.currency,
              'email': user.email,
              'fullName': payload.fullName
            })
            .catch(error => {
              commit('setLoading', false)
              commit('setError', error)
              $app.logError('Error creating user', {error: error.message})
              throw error
            })
        })
        .then(() => {
          $app.logInfo('Created db user')
          commit('setLoading', false)

          fb.analytics.logEvent('sign_up', {method: 'password'})
          fb.func.httpsCallable('notifySignUp')({
            account: {
              account: acct.account,
              type: acct.type,
              email: user.email,
              taxRegion: rootGetters.territory(acct.taxRegion)
            }
          })

          return dispatch('autoSignIn', {
            'account': payload.account,
            'fullName': payload.fullName,
            ...user
          })
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          $app.logError('Error signing up user', {error: error.message})
          throw error
        })
    },
    signUserIn ({commit}, payload) {
      commit('setLoading', true)
      commit('clearError')
      return fb.auth
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(() => {
          fb.analytics.logEvent('login', {method: 'password'})
          commit('setLoading', false)
          commit('setShowLogin', false)
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          this.$app.logError('Error signing in', {error: error.message})
        })
    },
    autoSignIn ({ commit, dispatch }, payload) {
      const user = {
        id: payload.uid,
        session_id: payload.session_id || ulid(),
        ...payload
      }

      commit('setUser', user)
      this.$app.logInfo('autoSignIn from Auth', {}, user)

      return Promise.all([
        dispatch('getDBUser', user.id)
          .catch(error => this.$app.logError('getDBUser', { error }))
          .then(userRef => {
            if (userRef) {
              commit('setFullName', userRef.get('fullName'))
            }

            return userRef && userRef.get('account')
          })
          .then(account => account && Promise.all([
            dispatch('bindAccountRef', account)
              .catch(error => this.$app.logError('bindAccountRef', { error })),
            dispatch('bindAddressBookRef', account)
              .catch(error => this.$app.logError('bindAddressBookRef', { error })),
            dispatch('bindConversations', account)
              .catch(error => this.$app.logError('bindConversations', { error })),
            dispatch('bindMessages', account)
              .catch(error => this.$app.logError('bindMessages', { error })),
            dispatch('bindFollows', account)
              .catch(error => this.$app.logError('bindFollows', { error }))
          ])),
        dispatch('bindCartRef', user)
          .catch(error => this.$app.logError('bindCartRef', { error })),
        dispatch('bindWishlistRef', user)
          .catch(error => this.$app.logError('bindWishlistRef', { error }))
      ]).then(
        () => dispatch('setAppLoading', false)
      )
      .catch(error => {
        commit('setLoading', false)
        commit('setError', error)
        this.$app.logError('Error signing in', {error: error.message})
        throw error
      })
    },
    resetPasswordWithEmail({ commit }, payload) {
      const $app = this.$app
      const returnUrl = window.location.protocol + '//' + window.location.host
      commit('setLoading', true)
      return fb.auth.sendPasswordResetEmail(payload, {url: returnUrl})
        .then(() => {
          commit('setLoading', false)
          $app.logInfo('Email Sent', payload)

          return
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
        })
    },
    logout ({ commit, dispatch }) {
      dispatch('setAppLoading', true)
      dispatch('setNav', false)

      fb.auth.signOut()
      commit('setUser', null)

      let unbinds = [
        'account',
        'conversations',
        'currentConversation',
        'messages',
        'follows',
        'cart',
        'list',
        'address',
        'accountProducts'
      ]

      return Promise.all(
        unbinds.map(unbind => dispatch('unbindFirebaseRefs', unbind))
      )
      .then(() => {
        commit('setAppLoading', false)
        commit('setLoading', false)
        commit('setJustSignedOut', true)
        return this.$app.$router.push('/', () => {})
      })
    },
    unbindFirebaseRefs(context, payload) {
      const $app = this.$app
      payload = Array.isArray(payload) ? payload : [payload]

      return firestoreAction(({ unbindFirestoreRef }, payload) => {
        payload.map(ref => {
          $app.logInfo('Unbinding ' + ref)
          unbindFirestoreRef(ref)
        })
      })(context, payload)
    },
    getDBUser(context, payload) {
      return fb.db.collection(COLLECTION).doc(payload).get()
    },
    updateUser({ commit, dispatch, state }, payload) {
      const $app = this.$app
      return fb.auth
        .signInWithEmailAndPassword(
          payload.currentEmail, payload.currentPassword
        )
        .then(userCredential => {
          const { user } = userCredential
          if (payload.email) {
            user.updateEmail(payload.email)
              .catch(error => {
                dispatch('setLoading', false)
                dispatch('setError', error)
                $app.logError('Error updating auth user email', error)
              })
          }
          if (payload.password) {
            user.updatePassword(payload.password)
              .catch(error => {
                dispatch('setLoading', false)
                dispatch('setError', error)
                $app.logError('Error updating auth user password', error)
              })
          }
          return user.updateProfile({ displayName: payload.fullName })
            .catch(error => {
              dispatch('setLoading', false)
              dispatch('setError', error)
              $app.logError('Error updating auth user profile', error)
            })
        })
        .then(() => {
          return fb.db.collection(COLLECTION).doc(payload.uid)
            .update(
              ['fullName', 'email'].reduce((acc, key) => {
                if (key in payload) {
                  acc[key] = payload[key]
                }
                return acc
              }, {})
            )
        })
        .then(() => {
          commit('setUser', {
            ...state.user,
            ...fb.auth.currentUser
          })
        })
        .catch(error => {
          dispatch('setLoading', false)
          dispatch('setError', error)
          $app.logError('Error updating user', {error: error.message})
        })
    },
    connectUserToStripe({ commit, state }, payload) {
      commit('setUser', {
        ...state.user,
        stripeCustomerID: payload
      })

      return fb.db.collection(COLLECTION).doc(state.user.uid)
        .update({
          'stripeCustomerID': payload
        })
        .catch(error => {
          this.$app.logError('Error storing stripeCustomerID on user', error)
        })
    },
    connectAccountToStripeID({ state }, payload) {
      const $app = this.$app
      return fb.db.collection('accounts')
        .doc(state.user.account)
        .update({
          'stripeUserID': payload,
          'type': 'seller'
        })
        .then(() => payload)
        .catch(error => {
          $app.logError('Error storing stripeUserID on account', error)
        })
    },
  },
  getters: {
    user: (state) => state.user,
    justSignedOut: (state) => state.justSignedOut,
    hasStripeCode: (state) => (code) => state.stripeConnectionCodes.includes(code)
  }
}
