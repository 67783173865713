import { firestoreAction } from 'vuexfire'
import fb from '@/firebase'
import Account from '@/aggroot/account'

const COLLECTION = 'accounts'

export default {
  state: {
    account: {}
  },
  getters: {
    account: (state) => state.account,
    isSeller: (state) => state.account && 'type' in state.account && state.account.type === 'seller'
  },
  mutations: {
    setAccount (state, payload) {
      state.account = {
        ...state.account,
        ...payload
      }
    }
  },
  actions: {
    setPinnedPost({ commit }, payload) {
      return fb.db.collection(COLLECTION)
        .doc(payload.account)
        .update({
          'pinnedPost': payload.postID,
          'updated': new Date().getTime()
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          this.$app.logError('Error pinning post', {error: error.message})
        })
    },
    checkAndClearPinnedPost({ commit }, { account, postID }) {
      return fb.db.collection(COLLECTION)
        .doc(account)
        .get()
        .then(doc => doc.get('pinnedPost') === postID &&
          fb.db.collection(COLLECTION)
          .doc(account)
          .update({
            'pinnedPost': null,
            'updated': new Date().getTime()
          })
          .then(() => this.$app.logInfo('Cleared pinned post'))
        )
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          this.$app.logError('Error clearing pinned post', {error: error.message})
        })
    },
    getAccount(context, payload) {
      return payload &&
        fb.db.collection(COLLECTION)
          .doc(payload)
          .get()
          .then(accountRef => accountRef && Account.fromObj({
            id: accountRef.id,
            ...accountRef.data(),
          }))
          .catch(error => this.$app.logError(
            'Error getting account',
            { payload, error: error.message }
          ))
    },
    getAccountsIn(context, payload) {
      const chunkSize = 10
      const promises = []
      const accounts = Array.from(new Set(payload))

      while (accounts.length) {
        let chunk = accounts.splice(0, chunkSize)
        promises.push(
          fb.db.collection(COLLECTION).where('account', 'in', chunk).get()
            .then(qs => qs.docs)
            .catch(error => this.$app.logError(
              'Error getting accounts',
              { payload, error: error.message }
            ))
        )
      }

      return Promise.all(promises)
        .then(docs => Array.isArray(docs) ? docs.flat() : [])
    },
    createAccount({ commit, dispatch }, payload) {
      const $app = this.$app
      const account = Account.fromObj(payload)
      return fb.db.collection(COLLECTION)
        .doc(account.account)
        .set(account.toObj())
        .then(() =>
          account.tags.length &&
          dispatch('updateTags', {
            tags: account.tags,
            collection: 'accounts',
            oldTags: false
          })
        )
        .then(() => account)
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          $app.logError('Error creating account', {error: error.message})
          throw error
        })
    },
    updateAccount({ commit, dispatch }, payload) {
      const $app = this.$app
      const oldTags = payload.oldTags
      delete payload.oldTags
      commit('setAccount', payload)
      return fb.db.collection(COLLECTION)
        .doc(payload.account)
        .update(payload.toObj())
        .then(() => {
          if ('tags' in payload) {
            dispatch('updateTags', {
              tags: payload.tags,
              collection: 'accounts',
              oldTags
            })
          }
        })
        .catch(error => {
          commit('setLoading', false)
          commit('setError', error)
          $app.logError('Error updating account', {error: error.message})
          throw error
        })
    },
    deleteAccount({ dispatch, commit }, payload) {
      const $app = this.$app
      const { account, email, password } = payload
      commit('setAppLoading', true)
      commit('setLoading', true)
      return Promise.all([
        fb.db.collection('addressbooks').doc(account).delete()
          .catch(error =>
            $app.logError('Error deleting addressbooks', { error })
          ),
        fb.db.collection('follows').where('followID', '==', account).get()
          .then(followDocs => !followDocs.empty &&
            Promise.all(followDocs.docs.map(follow =>
              fb.db.collection('follows').doc(follow.id).delete()
            ))
            .catch(error =>
              $app.logError('Error deleting followID', { error })
            )
          ),
        fb.db.collection('follows').where('follower', '==', account).get()
          .then(followDocs => !followDocs.empty &&
            Promise.all(followDocs.docs.map(follow =>
              fb.db.collection('follows').doc(follow.id).delete()
            ))
            .catch(error =>
              $app.logError('Error deleting follower', { error })
            )
          ),
        fb.db.collection('products').where('account', '==', account).get()
          .then(productDocs => !productDocs.empty &&
            Promise.all(productDocs.docs.map(product =>
              dispatch('deleteProduct', {productId: product.id})
            ))
            .catch(error =>
              $app.logError('Error deleting products', { error })
            )
          ),
        fb.db.collection('posts').where('account', '==', account).get()
          .then(postDocs => !postDocs.empty &&
            Promise.all(postDocs.docs.map(post =>
              dispatch('deletePost', post.id)
            ))
            .catch(error =>
              $app.logError('Error deleting posts', { error })
            )
          ),
        fb.db.collection('wishlists').where('account', '==', account).get()
          .then(listDocs => !listDocs.empty &&
            Promise.all(listDocs.docs.map(list =>
              fb.db.collection('wishlists').doc(list.id).delete()
            ))
            .catch(error =>
              $app.logError('Error deleting wishlists', { error })
            )
          ),
        fb.db.collection('carts').where('account', '==', account).get()
          .then(cartDocs => !cartDocs.empty &&
            Promise.all(cartDocs.docs.map(list =>
              fb.db.collection('carts').doc(list.id).delete()
            ))
            .catch(error =>
              $app.logError('Error deleting carts', { error })
            )
          ),
        fb.db.collection('users').where('account', '==', account).get()
          .then(userDocs => !userDocs.empty &&
            Promise.all(userDocs.docs.map(user =>
              user.id !== fb.auth.currentUser.uid &&
              fb.db.collection('users').doc(user.id).delete()
            ))
            .catch(error =>
              $app.logError('Error deleting users', { error })
            )
          )
      ])
        .then(() => fb.db.collection(COLLECTION).doc(account).delete())
        .then(() => fb.db.collection('users').doc(fb.auth.currentUser.uid).delete())
        .then(() => fb.auth.signInWithEmailAndPassword(email, password))
        .then(() => {
          commit('setAppLoading', false)
          commit('setLoading', false)
          return fb.auth.currentUser.delete()
        })
        .then(() => dispatch('logout'))
        .catch(error => {
          commit('setAppLoading', false)
          commit('setLoading', false)
          commit('setError', error)
          $app.logError('Error deleting account', {error})
        })
    },
    bindAccountRef(context, payload) {
      const $app = this.$app

      return firestoreAction((context, payload) => {
        $app.logInfo('Binding account to user ID', {account: payload})
        return context.bindFirestoreRef(
          'account',
          fb.db.collection(COLLECTION).doc(payload)
        )
      })(context, payload)
    }
  }
}
