import fb from '@/firebase'

const COLLECTION = 'tags'

const sanitizeTag = tag => {
  return tag
    .toString()
    .toLowerCase()
    .replace(/[\u200B-\u200D\uFEFF]/g, '')  // Zero-width chars
    .replace(/\s/g, ' ')
    .replace(/[_\u002D\u058A\u05BE\u1400\u1806\u2010-\u2015\u2E3A\u2E3B\u2E40\uFE58\uFE63\uFF0D]/g, '-')
    .trim()
}

const getUsageTotal = tagData => {
  return Object.entries(tagData.usage)
    .filter(([key]) => key !== 'total')
    .map(([, value]) => value)
    .reduce((a, b) => a + b, 0)
}

export default {
  state: {
    tags: {}
  },
  getters: {
    getTag: (state) => (tag) => {
      tag = sanitizeTag(tag)

      return (tag in state.tags) ?
        state.tags[tag] :
        fb.db.collection(COLLECTION).doc(tag).get()
          .then(doc => {
            if (doc.exists) {
              state.tags[tag] = doc.data()
              return state.tags[tag]
            } else {
              return false
            }
          })
    }
  },
  mutations: {
    setTag(state, payload) {
      state.tags[payload.tag] = payload
    }
  },
  actions: {
    getDocumentTags(context, { collection, docId }) {
      const $app = this.$app
      return fb.db.collection(collection).doc(docId).get()
        .then(doc => doc.get('tags'))
        .catch(error => {
          $app.logError('Issue in getDocumentTags', {collection, docId, error: error.message})
        })
    },
    getConsolidatedTag(context, tag) {
      const $app = this.$app
      const sanitizedTag = sanitizeTag(tag)
      return fb.db.collection(COLLECTION).doc(sanitizedTag).get()
        .then(doc => doc.data())
        .catch(error => {
          $app.logError('Issue in getConsolidatedTag', {sanitizedTag, error: error.message})
        })
    },
    removeTags({ dispatch }, { collection, oldTags }) {
      const $app = this.$app
      return Promise.all(
        Array.isArray(oldTags) ? oldTags.reduce((result, tag) => {
          if (tag) {
            result.push(dispatch('getConsolidatedTag', tag))
          }
          return result
        }, []) : []
      )
        .then(consolidatedTags => {
          return Promise.all(
            consolidatedTags.reduce((result, tag) => {
              if (result && tag) {
                if (collection in tag.usage &&
                  tag.usage[collection] > 0) {
                    tag.usage[collection] -= 1
                }
                tag.usage.total =  getUsageTotal(tag)
                $app.logInfo('Removing tag', tag)
                result.push(
                  fb.db.collection(COLLECTION).doc(tag.tag).set(tag)
                )
              }
              return result
            }, [])
          )
        })
        .catch(error => {
          $app.logError('Issue in removeTags', {collection, oldTags, error: error.message})
        })
    },
    updateTags({ dispatch }, { tags, collection, oldTags }) {
      return dispatch('removeTags', { collection, oldTags })
        .then(() => {
          return Promise.all(
            tags.map(tag => {
              return dispatch('updateTag', {tag, usage: collection})
            })
          )
        })
    },
    updateTag(context, { tag, usage }) {
      const $app = this.$app
      const sanitizedTag = sanitizeTag(tag)
      const tagRef = fb.db.collection(COLLECTION).doc(sanitizedTag)
      let tagData

      return tagRef.get()
        .then(doc => {
          if (doc.exists) {
            tagData = doc.data()

            if (usage in tagData.usage) {
              tagData.usage[usage] += 1
            } else {
              tagData.usage[usage] = 1
            }
          } else {
            const tagUsage = {}
            tagUsage[usage] = 1
            tagData = {
              created: new Date().getTime(),
              tag: sanitizedTag,
              usage: tagUsage
            }
          }

          tagData.usage.total = getUsageTotal(tagData)

          return tagRef.set(tagData)
        })
        .then(() => {
          $app.logInfo('Updated tag', {tagData})
          return tagData
        })
        .catch(error => {
          $app.logError('Issue in updateTag', {sanitizedTag, usage, error: error.message})
        })
    }
  }
}
